import axios from "axios";
import urls from "./ApiUrls";
export default {
    namespaced: true,
    state: {
        urls
    },
    actions: {
        async make(context, payload) {
            let options = {
                headers: payload.headers || {}
            };

            options.headers['Accept'] = 'application/json';
            options.headers['Content-Type'] = 'application/json';

            
            let url = payload.name ? (context.state.urls[payload.name] || "") : (payload.url || "");


            if (!payload.ignoreToken) //token
            {
                let token = await context.dispatch("Auth/token", null, { root: true });
                if (token) options.headers['Authorization'] = 'bearer ' + token;
            }
            else delete payload.ignoreToken;

            // if (!payload.ignoreLocale) //default locale
            // {
            //     options.headers['Content-Language'] = payload.selectedLocale || await context.dispatch("Locale/selected", null, { root: true });
            //     if (payload.selectedLocale) delete payload.selectedLocale;
            // }
            // else delete payload.ignoreLocale;
            options.headers['Content-Language'] = "en";

            if(payload.params && payload.params.urlParams)
            {
                payload.urlParams = payload.params.urlParams;
                delete payload.params.urlParams;
            }

            let urlParams = null;
            if(payload.urlParams)
            {   
                urlParams = payload.urlParams;
                delete payload.urlParams;
                if(url.substr(url.length - 1) != "/") url += "/";   
                if(urlParams.constructor == Object) urlParams = Object.values(urlParams);
                url += urlParams.join("/");
            }

            if ((['get']).includes(payload.method) && payload.params) {
                if (url.split("?").length == 1) url += '?';
                url += this._vm.jquery.param(payload.params);
                let headers = options.headers || null;
                if(options.headers) delete options.headers;
                payload.params = options;
                options = headers ? {headers} : null;
            }
            
            
            let api = await context.dispatch("Config/get", 'apiUrl', { root: true }) + context.state.urls['base_url'] || 'v1/';
            if(payload.cancelToken) options['cancelToken'] = payload.cancelToken;
            try
            {
                let response = await axios[payload.method || "post"]((!url || url?.substr(0, 4).toLowerCase() != 'http' ? api : '') + url, payload.params, options);
                return new Promise((resolve, reject) => {
                    if (response && response.data && response.data.status) {
                        resolve(response.data);
                    }
                    else {
                        response.data.error = true;
                        console.error("error.response", response);
                        reject(response.data);
                    }
                });
            }
            catch(error)
            {
                return new Promise((resolve, reject) => {
                    if(error.response && error.response.data)
                    {
                        if (typeof error.response?.data != "object") error.response.data = {
                            track: error.response.data
                        };
                        error.response.data.error = true;
                        console.error("error.response", error.response);
                        reject(error.response.data);
                    }                        
                });
            }
        },
        async post(context, payload) {
            if (!payload) payload = {};
            payload.method = 'post';
            return await context.dispatch('make', payload);
        },
        async get(context, payload) {
            if (!payload) payload = {};
            payload.method = 'get';
            return await context.dispatch('make', payload);
        },
        async delete(context, payload) {
            if (!payload) payload = {};
            payload.method = 'delete';
            return await context.dispatch('make', payload);
        },
        openUrl(context, options)
        {
            let url = typeof options == "string" ? options : (options.url || "");
            let target = options == "object" ? options.target || "_blank" : "_blank";

            window.open(url, target);
        },
        async Source(context)
        {
            return axios.CancelToken.source();
        }
    }
}