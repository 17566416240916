<template>
    <div>
     Home   
    </div>
</template>

<script>
export default {
    created() {
        this.$router.push({name: 'Projects'});
    },
}
</script>