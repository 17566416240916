export default {
    namespaced: true,
    actions: {
        async get({dispatch}, params)
        {
            try
            {
                let list = await dispatch("Api/post", {
                    params,
                    name: "accounts"
                }, {root: true});
                return list;
            }
            catch(e)
            {
                return await dispatch("handleError", e, {root: true});
            }    
        },
        async save({dispatch}, params)
        {
            try
            {
                let list = await dispatch("Api/post", {
                    params,
                    name: "saveAccount"
                }, {root: true});
                return list;
            }
            catch(e)
            {
                return await dispatch("handleError", e, {root: true});
            }    
        },
        async delete({dispatch}, params)
        {
            try
            {
                if(!params?.id) return await dispatch("handleError", {error: "No param id"});
                let id = params.id;
                return await dispatch("Api/post", {
                    params,
                    name: "deleteAccount",
                    urlParams: [id],
                }, {root: true});
            }
            catch(e)
            {
                return await dispatch("handleError", e, {root: true});
            }    
        },
    }
}