import Vue from 'vue'
import Vuex from 'vuex'
import Store from "@/Store"
import App from '@/App.vue'
import VueCryptojs from 'vue-cryptojs'

import "bootstrap/dist/css/bootstrap.css"
import "@/App.scss"
import router from '@/Store/Modules/Routes/Sources'
import "@/driectives/focus"
import "@/driectives/select"
import "@/driectives/copy"


//crypto
Vue.use(VueCryptojs);


Vue.use(Vuex);
const store= new Vuex.Store(Store);
window._Route = router;



Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: function (h) { return h(App) },
    data() {
        return {
            TheRoute: null
        }
    },
     created() {        
        Vue.prototype.$CurrentRoute = this.$route;
        Vue.prototype.$CurrentRouter = this.$router;
    },
    watch:{
        '$route': {
            deep: true,
            handler()
            {
                Vue.prototype.$CurrentRoute = this.$route;
            }
        }   
    },
}).$mount('#app');
