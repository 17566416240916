<template>
    <div>
        <router-link class="btn btn-primary mb-2" :disabled="!  perm('admin')" to="projects/add">Add Project</router-link>        
        <table class="table table-hover table-bordered ">
            <thead class="table-warning ">
                <tr>
                    <th>Project Name</th>
                    <th class="text-center ">Created By</th>
                    <th class="text-center w-50 ">Files</th>
                    <th class="text-center">Tag</th>
                    <th class="text-center">Created by</th>
                    <th class="text-center w-10 ">Tools</th>
                </tr>
            </thead>
            <preload-row-blocks :cols="6" v-if="loading" />
            <tbody v-else-if="list.results && list.meta.total > 0">
                <tr v-for="(row, i) in list.results" :key="i" :class="classing(row.tag)">
                    <td>{{row.app_name}}</td>
                    <td class="text-center ">{{row.created_by.name || "-"}}</td>
                    <td class="text-center" v-if="!row.show_files || (row.files && Object.keys(row.files).length == 1)">
                        <a @click="row.show_files = true;$forceUpdate()" class="d-block pointer link-info " v-if="Object.keys(row.files || {}).length > 1">Files ({{Object.keys(row.files || {}).length || 0}})</a>
                        <div v-else>
                            <router-link class="pointer d-block" :to="f != '-' ? 'projects/'+row.id+'/'+f : null" v-for="(u, f, i) in row.files || {'': '-'}" :key="i">{{f}}</router-link>
                        </div>
                    </td>
                    <td v-else>
                        <div class="list-group">
                            <router-link v-for="(url, file, i) in row.files || {}" :key="i" 
                                class="list-group-item list-group-item-action" :to="'projects/'+row.id+'/'+file">{{file}}</router-link>
                            <div class="list-group-item list-group-item-danger pointer" @click="row.show_files = false;$forceUpdate()">
                                <i class="fa fa-remove"></i>
                                Hide
                            </div>
                        </div>    
                    </td>
                    <td class="text-center " v-if="!row.del">{{row.tag}}</td>
                    <td class="text-center " v-if="!row.del">{{row.created_by?.name}}</td>
                    <td class="text-center " v-if="!row.del && !row.loading">                        
                        <a class="pointer me-2" title="Reset to last commit" @click="reset(row)">
                            <i class="fa fa-repeat fs-5 text-success" :class="{'fa-spin': row.reseting}"></i>
                        </a>
                        <a class="pointer me-2" v-if="perm('admin')" title="Reload all project from the server" @click="reload(row)">
                            <i class="fa fa-refresh fs-5 text-success" :class="{'fa-spin': row.reloading}"></i>
                        </a>
                        <router-link v-if="perm('admin')" :to="'projects/'+row.id"><i class="fa fs-5 fa-pencil pointer"></i></router-link>
                        <a v-if="perm('admin')" @click="row.del = true;$forceUpdate()" class="pointer ps-2"><i class="fa fs-4 fa-remove text-danger"></i></a>
                    </td>
                    <td class="text-center " v-else-if="row.loading">
                        <i class="fa fa-spin fa-spinner"></i>
                    </td>
                    <td colspan="2" v-if="row.del">
                        <actions mode="danger" 
                            :title="'Delete the project '+row.app_name" :verifyKey="row.app_name_url"
                            :description="'You are going to delete the peoject ' + row.app_name + ' forever'"
                            @answer="status => del(row, status)"
                            />
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination v-if="list.results" :callback="listing" :rs="list.meta" :loading="loading" />
    </div>
</template>

<script>
import Actions from '@/plugins/Actions.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '../plugins/Pagination.vue'
import PreloadRowBlocks from '../plugins/PreloadRowBlocks.vue'
export default {
    components: { Pagination, PreloadRowBlocks, Actions },
    data() {
        return {
            list: {},
            loading: true,
            filters: {
                with: [
                    "createdBy",
                ],
                orderBy: {
                    ord: 'asc'
                }
            }
        }
    },
    computed: {
        ...mapGetters("Settings", ['classing'])
    },
    methods: {
        ...mapActions("Projects", ["get", "delete", "project"]),
        ...mapActions("Routes", ["setPath"]),
        ...mapActions('Messages', ['hasError', 'clear', 'success']),
        async p(perm)
        {
            return await this.perm(perm);
        },  
        async listing(page)
        {
            if(!page) page = 1;
            this.filters.page = page;
            this.loading = true;
            this.list = await this.get(this.filters);
            if(this.list.meta.total == 0 && this.filters.page > 1)
            {
                this.filters.page --;
                await this.listing(this.filters.page);
            }
            else this.loading = false;
        },
        async del(row, status)
        {
            // console.log("row", row);
            row.del = false;
            if(!status) 
            {
                this.$forceUpdate();
                return false;
            }
            row.loading = true;
            this.$forceUpdate();

            await this.delete({id: row.id});

            await this.listing(this.filters.page);
            
        },
        async reset(project)
        {
            this.clear();
            project.reseting = true;
            this.$forceUpdate();
            let response = await this.project({id: project.id, command: 'reset'});
            if(await this.hasError(response)) return project.reseting = false;
            project.reseting = false;
            this.success(project.app_name + " has been reset to last commit");
            this.$forceUpdate();
        },
        async reload(project)
        {
            this.clear();
            project.reloading = true;
            this.$forceUpdate();
            let response = await this.project({id: project.id, command: 'lastCommit', reload: true});
            if(await this.hasError(response)) return project.reseting = false;
            project.reloading = false;
            this.success(project.app_name + " has been reloaded to default");
            this.$forceUpdate();
        },
    },
    mounted() {
        this.listing();
        this.setPath({
            Projects: "projects/"
        });
    },
}
</script>