import Home from "@/components/Home"
import Login from "@/components/Login"
import Projects from "@/components/Projects"
import Project from "@/components/Project"
import ProjectFile from "@/components/ProjectFile"
import Forbidden from "@/components/Forbidden"
import Accounts from "@/components/Accounts"
import Profile from "@/components/Profile"
import Settings from "@/components/Settings"


export default [
    {
        name: "Home",
        path: "/",
        component: Home,
        meta: { auth: true }
    },
    {
        name: "Forbidden",
        path: "/forbidden",
        component: Forbidden
    },
    {
        name: "Login",
        path: "/login",
        component: Login,
        meta: { guest: true }
    },
    {
        name: "Projects",
        path: "/projects",        
        component: Projects,
        meta: { auth: true }
    },
    {
        name: "Project",
        path: "/projects/:id",
        component: Project,
        meta: { auth: true , perm: ["admin"]}
    },
    {
        name: "ProjectFile",
        path: "/projects/:id/:file",
        component: ProjectFile,
        meta: { auth: true }
    },
    {
        name: "Accounts",
        path: "/accounts",
        component: Accounts,
        meta: { auth: true, perm: ['admin'] }
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
        meta: { auth: true, perm: ['admin'] }
    },
    {
        name: "Profile",
        path: "/profile",
        component: Profile,
        meta: { auth: true }
    },
]