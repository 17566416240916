<template>
    <div class="card" >
        <div class="card-header " :class="'text-bg-'+status">
            <h4 class="card-title">{{title}}</h4>
        </div>
        <div class="card-body">
            <p class="card-text" v-if="description">{{description}}</p>
            <div class="card-text">
                Enter the following to confirm: <span class="p-1" :class="'text-bg-'+status">{{verifyKey}}</span>
                <div class="mt-2"><input type="text" class="form-control form-control-sm" v-model="confirm" onfocus="this.select" /></div>
            </div>
            <div class="mt-3">
                <button type="button" @click="response(true)" class="btn" :disabled="!verifyKey || (confirm != verifyKey)" :class="'btn-'+status">
                    Ok
                </button>
                <button type="button" @click="response(false)" class="btn ms-2">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        mode: {
            required: true,
            type: String
        },
        title: {
            type: String,
            require: true
        },
        description: {
            type:String
        },
        verifyKey: {
            type: String
        }
    },
    data() {
        return {
            confirm: ''
        }
    },
    computed: {
        status()
        {
            // switch(this.mode) //later
            // {
            //     case ''
            // }
            return ['danger', 'warning', 'info'].includes(this.mode) ? this.mode : 'danger';
        }
    },
    methods: {
        response(status)
        {
            this.$emit('answer', status);
        },
    },
}
</script>