
export default {
    namespaced: true,
    state: {
        messages: [],
        time: 10, // sec
    },
    actions: {
        add({state, dispatch}, payload)
        {
            if(typeof payload != 'object' || !payload.message) return;            
            state.messages.push(payload);            
        },
        remove({state}, index)
        {
            state.messages.splice(index, 1);
        }
    }
}