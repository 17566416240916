
export default {
    namespaced: true,
    state: {
        key: null,
        prefix: process.env.NODE_ENV || null
    },
    getters: {
        getKey(state, getters, rootState, rootGetters) {
            if (!state.key) state.key = rootGetters['Config/get']('storage').key;
            return state.key;
        },
        prefix(state)
        {
            return state.prefix ? state.prefix + "_" : "";
        }
    },
    actions: {
        setObject({ getters }, payload) {
            let value = JSON.stringify(payload.value);
            value = this._vm.CryptoJS.AES.encrypt(value, getters.getKey);
            return window.localStorage.setItem(getters.prefix + payload.key, value);
        },
        setString({ getters }, payload) {
            let value = this._vm.CryptoJS.AES.encrypt(payload.value, getters.getKey);
            return window.localStorage.setItem(getters.prefix + payload.key, value);
        },
        async set({ dispatch }, list) {
            if (typeof list !== 'object') return console.error("Storage set Error", "list is not object, the type is ", typeof list);

            for (var key in list || {}) {
                let value = list[key];
                await dispatch(typeof value == "string" ? 'setString' : "setObject", { key, value });
            }
        },
        async get({ getters }, key) {
            let value = window.localStorage.getItem(getters.prefix + key);
            if (value) {
                value = this._vm.CryptoJS.AES.decrypt(value, getters.getKey).toString(this._vm.CryptoJS.enc.Utf8);
                try
                {
                    value = value ? JSON.parse(value) : null;
                }
                catch(e)
                {
                    value = null;
                }
            }
            return value;
        },
        async getString({ getters }, key) {
            let value = window.localStorage.getItem(getters.prefix + key);
            if (value) value = this._vm.CryptoJS.AES.decrypt(value, getters.getKey).toString(this._vm.CryptoJS.enc.Utf8);
            return value;
        },
        async remove({getters}, key) {
            if (typeof key == "string") key = [key];
            let removed = [];
            for (var i in key) removed.push(window.localStorage.removeItem(getters.prefix + key[i]));

            return removed;
        }
    }
}