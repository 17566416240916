
import Storage from "./Modules/Storage"
import Config from "./Config";
import Api from "./Modules/Api"
import Auth from "./Modules/Auth"
import Projects from "./Modules/Projects"
import Routes from "./Modules/Routes";
import Settings from "./Modules/Settings";
import Messages from "./Modules/Messages";
import Accounts from "./Modules/Accounts";
import Toast from "./Modules/Toast";

import mixins from "@/Helper";
import Vue from "vue";

Vue.mixin(mixins);

export default {
    namespaced: true,
    modules: {
        Storage,
        Config,
        Auth,
        Projects,
        Routes,
        Settings,
        Messages,
        Api,
        Accounts,
        Toast,
    },
    state: {
        init: false
    },
    actions: {
        async init({state, dispatch})
        {
            await dispatch('Settings/init');
            await dispatch("Auth/init");
            state.init = true;
            
        },
        handleError(methods, err)
        {
            let errors = [];
            
            if(err.results?.errors)
            {
                switch(typeof err.results.errors)
                {
                    case 'object':
                        for(var k in err.results.errors)
                        {
                            let val = err.results.errors[k];
                            if(val)
                            {
                                if(typeof val == Array) errors.push(k+":" + val.join(" "));
                                else if(typeof val == "object") errors.push(k+":" + JSON.stringify(val));
                                else errors.push(k+":"+val);
                            }
                        }
                        break;
                    case 'array':
                        errors = err.results.errors;
                        break;
                    default:
                        errors = [err.results.errors];
                }
            }
            if(errors.length == 0) [err.results?.message || "Somthing wrong"];
            
            return {
                error: true,
                message: err.results?.message || err.message || "Somthing wrong",
                errors,
                response: err
            };
        }
    }
}