<template>
    <div class="">
        <form @submit.prevent="login" class="container-sm ">
            <div class="card">
                <div class="card-header bg-primary">
                    <h3 class="card-title text-white ">Login</h3>
                </div>
                <div class="card-body ">
                    <div class="alert alert-danger" v-if="error">{{error}}</div>
                    <div class="row mt-2">
                        <div class="col-md-2">User:</div>
                        <div class="col-md">
                            <input type="text" :disabled="loading" required v-model="form.email" class="form-control">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-2">Password:</div>
                        <div class="col-md">
                            <input type="password" :disabled="loading" required v-model="form.password"
                                class="form-control">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md text-center">
                            <button class="btn btn-primary" type="submit" :disabled="loading">
                                <i class="fa fa-lock"></i>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data() {

        return {
            form: {},
            loading: false,
            error: null
        }
    },
    computed: {
        ...mapState("Auth", ["auth"]),        
    },
    methods: {
        ...mapActions("Auth", ["setUser"]),
        ...mapActions("Api", ['post']),
        ...mapActions("Routes", ['setPath']),
        async login() {
            if(this.loading) return false;
            this.loading = true;    
            this.error = null;
            try
            {
                let user = await this.post({
                    name: "login",
                    params: this.form
                });
                // this.loading = false;
                console.log("user.results", user.results);
                this.setUser({user: user.results.user, token: user.results.token});
                console.log("auth", this.auth);
                
            }
            catch(e)
            {
                console.error(e);
                this.loading = false;
                this.showError(e.results.message);
            }
        },
        showError(message)
        {
            this.error = message;
        }
    },
    mounted() {
        this.setPath({
            "Login": '/login'
        });
    },
}
</script>