<template>
    <footer>
        <div class="fixed-bottom">
            <div class="bg-primary text-white text-sm-center fs-6">Languages Editor - Copy rights 2024 {{nextYear}}</div>
        </div>
        <toast />
    </footer>
</template>


<script>
import moment from "moment"
import Toast from '@/plugins/Toast.vue';
export default {
    components: {Toast,},
    computed: {
        nextYear()
        {            
            return moment().year() == 2024 ? "" : "-"+moment().year();
        }
    },
}
</script>