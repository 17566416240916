import moment from "moment";
import Vue from "vue";
const global = {
    extend(obj1, obj2)
    {
        for(var k in obj2)
        {
            let val = obj2[k];
            if(obj1[k] && typeof val == 'object')
            {
                
                obj1[k] = this.extend(this.fixObject(obj1[k], k), this.fixObject(val, k));
            }
            else obj1[k] = val;
        }
        return obj1;
    },
    date(date, format)
    {
        if(!format) format = 'YYYY-MM-DD hh:mm A';

        return moment(date).format(format);
    },
    fixObject(obj, key)
    {
        if(typeof obj == 'string')
        {
            var o = obj;
            obj = {};
            obj[key] = o;
        }
        return obj;
    },
    perm(perm)
    {
        return this.$store.getters["Auth/perm"](perm)
    },
    echo(str)
    {
        if(['array', 'object'].includes(typeof str)) return JSON.stringify(str, null, 4);

        return str;
    }
};
Vue.filter('d', global.date);
export default global