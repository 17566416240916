<template>
    <div>
        <div v-if="!form">
            <div class="row">
                <div class="col-md-9">
                    <input type="search"  class="form-control form-control-lg" @keyup.enter="listing" @keyup.esc="filters.keywords = null;listing()"                        
                        v-model="filters.keywords" :disabled="loading" placeholder="Search by name or email" />
                </div>
                <div class="col-md-3">
                    <button class="btn btn-primary float-end  mb-3" type="button" @click="create()">
                        <i class="fa fa-plus"></i>
                        Create Account
                    </button>
                </div>
            </div>
            <div class="table-responsive" v-if="!form">
                
                <table class="table">
                    <thead class="table-warning">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th class="text-center ">Email</th>                    
                            <th class="text-center ">Status</th>
                            <th class="text-center ">Permission</th>
                            <th class="text-center ">Create at</th>
                            <th class="text-center ">Tools</th>
                        </tr>
                    </thead>
                    <preload-row-blocks :cols="7" v-if="!list.results" />
                    <tbody v-else-if="list.meta.total > 0">
                        <tr v-for="(user, i) in list.results" :key="i">
                            <td>{{user.id}}</td>
                            <td>{{user.name}}</td>
                            <td class="text-center ">{{user.email}}</td>
                            <td class="text-center ">
                                <i class="fa fs-4" :class="user.status ? 'fa-user text-success' : 'fa-user-times text-danger'"></i>    
                            </td>
                            <td class="text-center ">
                                <i class="fa fa-user-secret fs-4" title="Administrator" v-if="user.role_id"></i>
                            </td>
                            <td class="text-center " v-if="!user.del">
                                {{user.created_at|d}}
                            </td>
                            <td class="text-center " v-if="!user.del">
                                <i v-if="!loading" class="fa fs-5 pointer fa-pencil text-success " @click="edit(user)"></i>
                                <i v-else class="fa fs-5 text-muted "></i>
                                <i v-if="user.id != auth.user.id && !loading" class="fa fs-5 pointer fa-remove text-danger ms-2" @click="user.del = true;$forceUpdate()"></i>
                                <i v-else class="fa fs-5 fa-remove text-muted  ms-2"></i>
                                <i v-if="user.loading" class="fa fs-5 fa-spin fa-spinner  ms-2"></i>
                            </td>
                            <td colspan="2" v-if="user.del">
                                <actions mode="danger" 
                                    :title="'Delete the user '+user.name" :verifyKey="user.email"
                                    :description="'You are going to delete the user ' + user.name + ' forever'"
                                    @answer="status => del(user, status)"
                                    />
                            </td>
                        </tr>
                    </tbody>
                    <tr v-else>
                        <td colspan="6" class="table-secondary ">No Rows found !</td>
                    </tr>
                </table>
                <pagination v-if="list.results" :callback="listing" :rs="list.meta" :loading="loading" />
            </div>
        </div>

        <div v-else class="container ">
            <form @submit.prevent="saveAccount">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">{{form.id ? 'Edit user ' + originalForm.name : 'Create a new user'}}</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Name</label>
                                <input type="text" required v-model="form.name" class="form-control" :disabled="loading" />
                            </div>
                            <div class="col-md-6">
                                <label for="">Email</label>
                                <input type="email" required v-model="form.email" class="form-control" :disabled="loading" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="">Password</label>
                                <input type="text" v-model="form.password" :required="!form.id ? true : false" class="form-control" :disabled="loading" />
                                <div class="alert alert-warning" v-if="form.id">
                                    <i class="fa fa-info"></i>
                                    Leave Password blank in case no changes
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="pointer mt-4" @click="form.status = !form.status;$forceUpdate()" v-if="form.id != auth.user.id && !loading">
                                    <i class="fa fs-3" 
                                        :class="form.status ? 'fa-toggle-on text-success' : 'fa-toggle-off text-danger'"></i>
                                    Status: {{form.status ? 'Active' : 'Disabled'}}
                                </div>
                                <div class="mt-4" v-else>Status: {{form.status ? 'Active' : 'Disabled'}}</div>
                            </div>
                            <div class="col-md-3">
                                <div class="pointer mt-4" @click="form.role_id = form.role_id ? null : 1;$forceUpdate()" 
                                    v-if="form.id != auth.user.id && !loading">
                                    <i class="fa fs-3"
                                        :class="form.role_id ? 'fa-toggle-on text-success' : 'fa-toggle-off text-info'"></i>
                                    {{form.role_id ? 'Aministrator' : 'User'}}
                                </div>
                                <div class="mt-4" v-else>{{form.role_id ? 'Aministrator' : 'User'}}</div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-">
                                <button class="btn btn-primary " :disabled="loading" type="submit">
                                    Save
                                </button>
                                <button class="btn" :disabled="loading" type="button" @click="originalForm = form = null;$forceUpdate()">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Pagination from '@/plugins/Pagination.vue';
import { mapActions, mapState } from 'vuex';
import PreloadRowBlocks from '@/plugins/PreloadRowBlocks.vue';
import Actions from '@/plugins/Actions.vue';
import collect from 'collect.js';
export default {
  components: { Pagination, PreloadRowBlocks, Actions, },
    data() {
        return {
            filters: {
                page: 1,
                orderBy: {
                    id: 'desc'
                }
            },
            list: {},
            loading: true,
            form: null,
            originalForm: null,
        }
    },
    computed: {
        ...mapState("Auth", ["auth"])
    },
    methods: {
        ...mapActions('Accounts', ['get', 'save', 'delete']),
        ...mapActions('Messages', ['hasError', 'clear', 'success', 'error']),
        ...mapActions("Routes", ['setPath']),
        async listing(page)
        {
            if(!page) page = 1;
            this.filters.page = page;
            this.loading = true;
            this.list = await this.get(this.filters);
            if(this.list.meta.total == 0 && this.filters.page > 1)
            {
                this.filters.page --;
                await this.listing(this.filters.page);
            }
            else this.loading = false;
        },
        create()
        {
            this.clear();
            this.form = {
                status: true
            };
            this.originalForm = null;
            this.$forceUpdate();
        },
        edit(user)
        {
            this.clear();
            this.form = {...user};
            this.originalForm = user;
            this.$forceUpdate();
        },
        async saveAccount()
        {
            this.clear();
            this.loading = true;
            if(this.originalForm) 
            {
                this.originalForm.loading = true;
                this.$forceUpdate();
            }
            let form = collect(this.form);
            let response = await this.save(form.only(['id', 'email', 'name', 'password', 'status', 'role_id']).all());
            this.loading = false;
            if(await this.hasError(response)) return this.$forceUpdate();
            else 
            {
                
                if(this.form.id)
                {
                    for(var k in response.results)
                    {
                        this.originalForm[k] = response.results[k];
                    }
                    delete this.originalForm.loading;
                    this.success(this.form.name + " has been updated successfully");
                }
                else
                {
                    this.success(this.form.name + " has been created successfully");
                    this.listing();
                }
                this.originalForm = this.form = null;
            }
            this.$forceUpdate();
        },
        async del(user, status)
        {
            delete user.del;
            this.$forceUpdate();
            if(!status) return;

            user.loading = true;
            this.$forceUpdate();
            let response = await this.delete(user);
            if(await this.hasError(response)) return;
            if(response.results?.deleted) this.success(user.name + " has been deleted successfully");
            await this.listing(this.filters.page);
        }
    },
    mounted() {
        if(!this.perm('admin')) this.$router.push({name: "Forbidden"});
        this.setPath({
            "Accounts" : "/accounts"
        });
        this.listing(1);
    },
}
</script>