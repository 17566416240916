<template>
    <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <router-link to="/" class="navbar-brand">
                {{settings?.AppName}}
            </router-link>
            
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-lg-0">
                    <li class="nav-item" v-for="(path, name) in menu" :key="name" v-show="!path.url || perm(path.perm)">
                        <router-link class="nav-link" :class="{'active': active == name}" aria-current="page" :to="path?.url || path">{{name}}</router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link disabled" aria-disabled="true">Disabled</a>
                    </li> -->
                </ul>
                <h3 v-if="title" class="navbar-nav mb-lg-0 me-auto">{{title}}</h3>
                <!-- <form class="d-flex" role="search">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">Search</button>
                </form> -->
                <div v-if="auth && auth.user" class="d-flex navbar-nav mb-lg-0">
                    Welcome  
                    <router-link to="/profile" class="text-decoration-none text-success ">
                        <i class="fa fa-user ms-2 me-1"></i>
                        {{auth.user.name || ""}}
                    </router-link>,
                    <a class="pointer ms-2 text-danger-emphasis" @click="logout">Logout</a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            menu: {
                Home: "/",
                Projects: "/projects",
                Accounts: {
                    url: "/accounts",
                    perm: 'admin'
                },
                Settings: {
                    url: "/settings",
                    perm: 'admin'
                },
            }
        }
    },
    computed: {
        ...mapState("Routes", ["title"]),
        ...mapState("Auth", ["auth"]),    
        ...mapState("Settings", ["settings"]),    
        active()
        {
            return this.$route.name || null;
        }
    },
    methods: {
        ...mapActions("Auth", {"logingout":"logout"}),
        logout()
        {
            if(confirm("Do you want to logout ?"))
            {
                this.logingout();
                this.$router.push({name: 'Login'});
            }
        }
    }
}
</script>