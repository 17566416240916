<template>
    <div class="card">
        <div class="card-header">
            <div class="dropdown float-end" v-if="data && data.branch">
                <button class="btn btn-info  dropdown-toggle" type="button" 
                    :disabled="!perm('admin') || loading || h_loading" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa fa-sitemap"></i> {{data.branch}}
                </button>
                <ul class="dropdown-menu">
                    <li v-for="b in branches" :key="b">
                        <a class="dropdown-item pointer" @click="selectBranch(b)">{{b}}</a>
                    </li>
                </ul>
            </div>
            <h3 class="card-title">
                <i class="fa fa-globe"></i>
                <a :href="data ? data.project?.repository_url_pure : null" target="_blank">{{ $route.params.file }}</a>
                <small v-if="data">[{{ data.project?.app_name}}]</small>                 
            </h3>
            
        </div>
        <div class="card-body">
            <div class="mb-3">
                <button type="button" class="btn btn-primary" @click="saving" :disabled="Object.keys(history).length == 0 || loading">
                    <small class="badge bg-danger ">{{Object.keys(history).length}}</small>
                    <i class="fa fa-spin fa-spinner ms-2" v-if="loading"></i>
                    Commit Changes
                </button>
                <button type="button" class="btn btn-warning ms-2" @click="load()" :disabled="loading">
                    <i class="fa fa-refresh"></i>
                    Refresh
                </button>
                <button class="btn btn-success ms-2" @click="addKey" :disabled="loading">
                    <i class="fa fa-plus"></i>
                    Add Key
                </button>
            </div>
            <div class="table-responsive-xxl scroll-container"  style="max-height: 75vh;">
                <table class="table table-headers table-hover table-striped  table-bordered   w-100">
                    <thead class="table-warning position-sticky top-0">
                        <tr v-if="loading">
                            <th>Key</th>
                            <th><preload-block /></th>
                        </tr>
                        <tr v-else>
                            <th>Key</th>
                            <!-- <th v-for="k in data.languages" :key="k">{{ k }}</th> -->
                            <th style="min-width: 200px;" v-for="(path, k, i) in data?.paths || {}" :key="i" :title="path">
                                <a :href="data.project?.repository_url_pure+'/-/blob/'+data.branch+'/'+path" target="_blank" class="">{{ k }}</a>
                            </th>
                        </tr>
                    </thead>
                    <preload-row-blocks :cols="2" :rows="5" v-if="loading" />
                    <tbody v-else>
                        <phrase :new="newKey == key" v-for="(row, key, i) in data.files || {}" :key="i" :row="row" :id="key" :locales="data.languages"
                            :select="selectToEdit && selectToEdit[0] && selectToEdit[0] == key ? selectToEdit[1] || null : null"
                            @delete="deleteKey(key)"
                            @changed="addToHistory" />
                    </tbody>
                </table>
            </div>
            <div class="mt-3">
                <button type="button" class="btn btn-primary" @click="saving" :disabled="Object.keys(history).length == 0 || loading">
                    <small class="badge bg-danger ">{{Object.keys(history).length}}</small>
                    Commit Changes
                </button>
                <button type="button" class="btn btn-warning ms-2" @click="load()" :disabled="loading">
                    <i class="fa fa-refresh"></i>
                    Refresh
                </button>
                <button class="btn btn-success ms-2" @click="addKey" :disabled="loading">
                    <i class="fa fa-plus"></i>
                    Add Key
                </button>
            </div>         
        </div>
        <div v-if="history.length > 0">
            <div class="card-header">
                <h3>Changes History</h3>
            </div>
            <div class="card-body">
                <div class="scroll-container-down" style="max-height: 300px;">
                    <table class="table table-headers table-striped table-hover table-bordered">
                        <thead class="table-dark position-sticky top-0">
                            <tr>
                                <th>Key</th>
                                <th>Local</th>
                                <th>from</th>
                                <th>to</th>
                                <th class="w-5">Tools</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(h, i) in history" :key="i">
                                <td><b>{{h.key}}</b></td>
                                <td>{{h.lang}}</td>
                                <td>{{h.from}}</td>
                                <td>{{h.to}}</td>
                                <td>
                                    <a class="pointer" v-if="(h.to && typeof h.to != 'object')" @click="addToHistory(h.key,h.lang,h.to,h.from)">
                                        <i class="fa fa-repeat fs-5"></i>
                                    </a>    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreloadBlock from '@/plugins/PreloadBlock.vue';
import { mapActions } from 'vuex'
import PreloadRowBlocks from '@/plugins/PreloadRowBlocks.vue';
import Phrase from '@/plugins/Phrase.vue';
export default {
    components: { PreloadBlock, PreloadRowBlocks, Phrase },
    data() {
        return {
            data: null,
            loading: true,
            h_loading: false,
            history: [],
            selectToEdit: null,            
            branches: {},
            newKey: null
        }
    },
    methods: {
        ...mapActions('Projects', ['loadFiles','project', 'save']),
        ...mapActions('Routes', ['setPath']),
        ...mapActions('Messages', ['success', 'error', 'clear', 'info']),
        async load(command, branch) {
            this.clear();
            if(!command) command = 'pull';//last-comment
            this.loading = true;
            let response = await this.loadFiles({
                id: this.$route.params.id,
                command,
                branch,
                file: this.$route.params.file
            });

            if(!response || response.error) 
            {
                let errors = response?.message || "Error";
                if(response.errors) errors += "\n- " + response.errors.join("\n- ");
                this.error(errors);
                return this.$forceUpdate();
            }
            if(response.last_commit) this.info(response.last_commit.commit);
            // console.log("response", response);
            this.data = response;
            this.loading = false;
            let paths = {
                Projects: '/projects',                
            };
            this.data.project.repository_url_pure = this.data.project.repository_url.substr(this.data.project.repository_url.length - 4, 4) == '.git' 
                ? this.data.project.repository_url.substr(0, this.data.project.repository_url.length - 4) : this.data.project.repository_url;            
            paths[this.data.project?.app_name || "-"] = "/projects/"+this.$route.params.id
            paths["File: "+this.$route.params.file] = "/projects/"+this.$route.params.id+"/"+this.$route.params.file
            this.setPath(paths);
            this.history = [];
            this.loadBranchs();
            this.$forceUpdate();
            // console.log("this.project", this.data);
        },
        addToHistory(key, lang, from, to)
        {
            this.selectToEdit = this.newKey = null;
            // console.log("to", to);
            if(typeof to == 'object')
            {
                this.data.files[key] = {...to};
            }
            else
            {                
                this.data.files[key][lang] = to;
            }
            this.history.push({key, lang, from, to});
            this.$forceUpdate();
        },
        async saving()
        {       
            this.clear(); 
            window.scrollTo(0, 0);           
            this.loading = true;          
            let data = {files: {...this.data.files}, history: this.history, languages: this.data.languages, id: this.$route.params.id,
                file:this.$route.params.file, paths: this.data.paths, tag: this.data.project.tag};  
            let newData = await this.save(data);
            this.loading = false;
            // console.log("newData save", newData);
            if(newData?.results)
            {
                
                // this.data = newData;
                this.success(newData.results.last_commit?.commit || "Done");
                // this.message.comment = newData.results.last_commit?.commit || "";
                // if(this.message.comment) this.message.status = "success"
                this.history = [];
            }
            // else 
            // {
            //     this.message.comment = newData?.message || "Error";
            //     if(this.message.comment) this.message.status = "danger"
            // }
        },
        addKey()
        {
            let key = window.prompt("Type the key name: \n use '\\' in case child levels like: '{settings->app_name}' to be 'settings\\app_name'");
            if(!key) return;
            if(this.data.files[key])
            {
                alert("The key "+key+" is already exists");
                return;
            }

            this.data.files[key] = {};
            for(var l in this.data.languages)
            {
                var lng = this.data.languages[l];
                this.data.files[key][lng] = "";
            }
            this.selectToEdit = [key, this.data.languages[0]];
            this.newKey = key;
            this.$forceUpdate();
        },
        deleteKey(key)
        {
            if(confirm("Do you want to delete "+key+" ?"))
            {                
                this.history.push({
                    key, 
                    from: this.data.files[key],
                    to: 'Delete'
                });
                delete this.data.files[key];
                this.$forceUpdate();
            }
        },
        loadBranchs()
        {
            this.branches = this.getBranches(this.data.branches);
            // console.log("this.branches", this.branches);
        },
        async selectBranch(branch)
        {
            this.load('checkout', branch);
        },
        
    },
    created() {
        
        if(isNaN(this.$route.params.id)) this.$router.push({name: 'Projects'});
        
    },
    async mounted() {
        await this.load();
    },
}
</script>