<template>
    <tbody>
        <tr v-for="ii in rows || 4" :key="ii">            
            <td v-for="i in cols" :key="i"> 
                <PreloadBlock />
            </td>
        </tr>    
    </tbody>
</template>

<script>
import PreloadBlock from './PreloadBlock.vue';
export default {
    props: {
        cols: {
            type: Number,
            required: true
        },
        rows: Number
    },
    components: { PreloadBlock }
}
</script>