/* eslint-disable */
export default {
    namespaced: true,
    state: {
        source: null
    },
    actions: {
        cancel({state})
        {
            if(state.source)
            {
                state.source.cancel();
                state.source = null;
            }
        },
        async get({dispatch}, params)
        {
            try
            {
                let list = await dispatch("Api/post", {
                    params,
                    name: "projects"
                }, {root: true});

                return list;
            }
            catch(e)
            {
                return await dispatch("handleError", e);
            }
        },
        async saveForm({dispatch}, params) {
            try
            {
                let project = await dispatch("Api/post", {
                    params,
                    name: "saveProject",
                }, {root: true});

                return project;
            }
            catch(e)
            {
                return await dispatch("handleError", e, {root: true});
            }   
        },
        async delete({dispatch}, params) {
            try
            {
                let id = params.id;
                delete params.id;

                let status = await dispatch("Api/post", {
                    params,
                    urlParams: [id],
                    name: "deleteProject"
                }, {root: true});

                return status;
            }
            catch(e)
            {
                return await dispatch("handleError", e);
            }
        },
        async save({dispatch}, params)
        {
            let files = this._vm.setLocales(params.files, params.languages);            
            let file = params.file;
            let commit = "";
            let id = params.id;
            let user = await dispatch('Auth/user', null, {root: true});
            // console.log("params.history[i]", params.history);
            // for(var i in params.history || {})
            // {
            //     let line = params.history[i];
            //     if(commit != "") commit += "[nl]";
            //     commit += "Update key '"+line.key+(line.lang ? "' in language '"+line.lang : '')+"' from: '"+this._vm.echo(line.from)+"' to: '"+this._vm.echo(line.to)+"' by "+user.name+".";
            // }
            if(commit.length > 72)
            {
                commit = commit.substring(0, 69)+"...";   
            }
            // console.log(">>>> commit", commit);
            let files_list = {};
            switch(params.tag)
            {
                case 'VUE':
                    for(var f in files || {})
                    {
                        files_list[f] = {
                            file: JSON.stringify(files[f], undefined, 4),
                            path: params.paths[f]
                        };
                    }
                    break;
                case 'PHP':
                default:
                    for(var f in files || {})
                    {
                        files_list[f] = {
                            file: files[f],
                            path: params.paths[f]
                        };
                    }
                    break;
            }
            let param = {commit, file, files_list, id, command: 'commit'};
            // console.log(param);

            return await dispatch("project", param);
            // console.log("comit results", results);
            // return results;
        },
        async project({dispatch, state}, params)
        {
            try
            {
                state.source = await dispatch("Api/Source", null, {root: true});
                // console.log("state.source", state.source);
                if(!params?.id) return await dispatch("handleError", {message: "No param id"}, {root: true});
                let id = params.id;
                delete params.id;
                let project = await dispatch("Api/post", {
                    params,
                    name: "project",
                    urlParams: [id],
                    cancelToken: state.source.token
                }, {root: true});
                dispatch('cancel');
                return project;
            }
            catch(e)
            {
                dispatch('cancel');
                return await dispatch("handleError", e, {root: true});
            }           
        },
        async loadFiles({dispatch}, params)
        {
            let response = await dispatch('project', params);
            
            // if(response.error) return response;
            if(response && response.results && response.results.files)
            {
                let project = response.results;
                project.languages = [];
                let files = {};
                project.paths = {};
                switch(project.project.tag)
                {
                    case 'PHP':
                        for(var langK in project.files)
                        {
                            project.languages.push(langK);
                            files[langK] = project.files[langK]['file'];
                            project.paths[langK] = project.files[langK]['path'];
                        }
                        break;
                    case 'VUE':
                        for(var langK in project.files)
                        {
                            project.languages.push(langK);
                            try
                            {
                                
                                eval('files[langK] = ' + project.files[langK]['file'] + ';');
                            }
                            catch(e)
                            {
                                console.error("error decode on file: " + langK, e.message);
                                console.warn("data:", project.files[langK]['file']);
                                files[langK] = {};
                            }
                            project.paths[langK] = project.files[langK]['path'];
                        }
                        break;
                }
                let list = this._vm.getLocales(files);
                
                project.files = list;
                // console.log("project", project);
                return project;
            }
            else if(!response.status) return response;
            else await this.dispatch("handleError", {error:true, message:'Files Not Found'}, {root: true});
        }
    }
}