<template>
    <div class="container card" v-if="show">
        <div class="card-body">
            <form @submit.prevent="save">
                <div class="row">
                    <div class="col-md-6">
                        <label class="required">App Name</label>
                        <input :disabled="loading" type="text" v-model="form.app_name" placeholder="App Name" class="form-control" required />
                    </div>
                    <div class="col-md-6">
                        <label class="required">Email</label>
                        <input :disabled="loading" type="email" v-model="form.email" placeholder="Email" class="form-control" required />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label class="required">User Name</label>
                        <input :disabled="loading" type="text" v-model="form.username" placeholder="User Name" class="form-control" required />
                    </div>
                    <div class="col-md-6">
                        <label :class="{required: !form.id}">
                            Password
                        </label>
                        <div  v-if="!form.id || form.changePassword">
                            <input :disabled="loading" type="password" v-model="form.password" placeholder="Password" class="form-control" required />
                            <small v-if="form.id"> <a class="pointer" @click="clearPassword()">Cancel</a></small>
                        </div>
                        <div v-else class="p-2">
                            ************
                            <small> <a class="pointer" @click="form.changePassword = true;$forceUpdate()">Change password</a></small>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-8">
                        <label class="required" :class="{'text-success text-bold': form.checked, 'text-danger': form.checked === false}">
                            Repository URL
                            <i class="fa" :class="{'fa-check': form.checked, 'fa-times': form.checked === false}"></i>
                            <a v-if="form.repository_url_pure" :href="form.repository_url_pure" target="_blank" class="ms-2"><i class="fa fa-eye" ></i></a>
                        </label>
                        <input :disabled="loading" @blur="urlCheck(null)" type="url" v-model="form.repository_url" placeholder="Repository URL" 
                            :class="{'text-success text-bold': form.checked, 'text-danger': form.checked === false}" class="form-control" required />
                    </div>
                    <div class="col-md-2">
                        <label class="required">Project Type</label>
                        <select :disabled="loading" class="form-select" v-model="form.tag">
                            <option v-for="tag in tags" :key="tag" :value="tag">{{tag}}</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label class="required">Order</label>
                        <input :disabled="loading" type="number" v-model="form.ord" placeholder="Project order" class="form-control" required />
                    </div>
                </div>
                <div class="row mt-3" v-if="form.id">
                    <div class="col">
                        <hr>
                        <h4>
                            
                            Languages files
                            <button class="btn btn-success btn-sm" type="button" @click="addFile" :disabled="loading">
                                <i class="fa fa-plus"></i> Add new file
                            </button>
                        </h4>
                        <ul class="list-group">
                            <div v-for="(locales, name, i) in form.files || {}" :key="i">
                                <li class="list-group-item list-group-item-warning ">
                                    <div class="btn-group btn-group-sm float-end" role="group" aria-label="Basic example">
                                        <button class="btn btn-success" type="button" :disabled="loading" @click="addLocale(name)">
                                            <i class="fa fa-plus"></i>
                                            Add locale
                                        </button>
                                        <router-link class="btn btn-info pointer" :to="'/projects/'+form.id+'/'+name">
                                            <i class="fa fa-edit"></i>
                                        </router-link>
                                        <button class="btn btn-danger" type="button" :disabled="loading" @click="removeFile(name)">
                                            <i class="fa fa-remove"></i>                                            
                                        </button>
                                    </div>
                                   
                                    <h5><i class="fa fa-file"></i> {{name}}</h5>
                                    
                                    
                                </li>
                                <li class="list-group-item" v-for="(file, locale, ii) in locales" :key="ii">                                
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">
                                            <i class="fa fa-globe me-2"></i> <b>{{locale}}</b>
                                        </span>
                                        <input type="text" required v-model="locales[locale]" class="form-control" />
                                        <a v-if="!loading && locales[locale] && branch" 
                                            target="_blank" :href="form.repository_url_pure + '/-/blob/'+ branch + '/' + locales[locale]" 
                                            class="input-group-text text-decoration-none " :class="locales[locale] ? 'pointer text-success' : 'disabled text-muted '">
                                                <i class="fa fa-eye"></i>
                                        </a>
                                        <span v-if="!loading" class="input-group-text text-danger pointer" @click="removeLocale(name, locale)">
                                                <i class="fa fa-remove"></i>
                                        </span>
                                        <span v-else class="input-group-text">
                                                <i class="fa fa-spin fa-spinner"></i>
                                        </span>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <button class="btn btn-primary" type="submit" :disabled="loading">
                            <i class="fa" :class="{'fa-save': !saveLoading, 'fa-spin fa-spinner': saveLoading}"></i>
                            Save
                        </button>
                        <router-link to="/projects" class="btn btn-default">
                            <i class="fa fa-replay"></i>
                            Cancel
                        </router-link>
                        <div class="dropdown float-end" v-if="branches && branches.length > 0">
                            <button class="btn btn-info  dropdown-toggle" type="button" :disabled="!perm('admin') || loading" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa fa-sitemap"></i> {{branch}}
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="b in branches" :key="b">
                                    <a class="dropdown-item pointer" @click="selectBranch(b)">{{b}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    data() {
        return {
            tags: {},
            mode: null,
            form: {},
            loading: true,
            saveLoading: false,
            branches: [],
            branch: null,
            show: true
        }
    },
    computed: {
        ...mapState("Auth", ['auth'])
    },
    methods: {
        ...mapActions('Routes', ['setPath']),
        ...mapActions('Projects', {saveForm: 'saveForm', loadGit: 'project', loadProject: 'get', cancelRequest: 'cancel'}),
        ...mapActions('Settings', {settings: 'get'}),
        ...mapActions('Messages', ['hasError', 'clear', 'success', 'error']),
        async load()
        {
            this.clear();
            if(!this.form.id)
            {
                this.loading = false;
                this.setPath({
                    Projects: '/projects',
                    "add Project": '/projects/add'
                });
                this.$forceUpdate();
            }
            else if(!isNaN(this.form.id))
            {
                this.git('pull');
            }
        },
        async save()
        {
            this.clear();
            this.loading = this.saveLoading = true;
            this.$forceUpdate();
            let form = {...this.form};
            delete form.app_name_url;
            let response = await this.saveForm(form);
            if(await this.hasError(response)) return;

            if(!this.form.id)
            {
                this.loading = this.saveLoading = false;
                this.$router.push({name: 'Project', params: {id: response.results.id}});
                return;
            }
            this.loading = this.saveLoading = false;
            this.git('pull');
            this.$forceUpdate();            
        },
        async git(command, options) {
            this.clear();
            this.loading = true;
            
            if(!options) options = {};
            if(!command) command = 'lastCommit';
            let response = await this.loadGit({
                id: this.$route.params.id,
                command,              
                ...options  
            });
            
            if(await this.hasError(response)) 
            {
                this.form.checked = false;
                // console.log("responseresponseresponse err", response);
                if(response.response?.results?.errors?.project)
                {
                    this.form = response.response?.results?.errors?.project;
                    this.form.checked = false;
                    this.loading = false;
                }
                else this.show = false;
                window.scrollTo(0, 0);
                return;
            }
            else if(response.results?.project)
            {
                this.form = response.results.project;
                let paths = {
                    Projects: '/projects',                    
                };
                paths["Edit Project " + this.form.app_name] = '/projects/'+this.form.id;
                this.setPath(paths);
                this.getRepoURL();
            }

            if(response.results?.branches) 
            {
                this.loadBranchs(response.results.branches);
                this.branch = response.results.branch || null;
            }
            
            if(response.results?.last_commit && response.results.last_commit?.commit) 
            {
                this.success("Repository Connected, Last commit for branch \""+this.branch+"\":\n"+response.results.last_commit.commit + "\n" + "- Git upload by:" + response.results.last_commit.name);
            }
            this.form.checked = true;
            
            this.loading = false;
            this.$forceUpdate();
            window.scrollTo(0, 0);
        },
        clearPassword()
        {
            delete this.form.changePassword;
            delete this.form.password;
            this.$forceUpdate()
        },
        addLocale(name, defaultN)
        {
            let file = this.form.files[name] || null;
            if(!file) return false;
            let key = window.prompt("Please Type the Language key:", defaultN);
            if(!key) return;
            if(file[key]) 
            {
                this.clear();
                return this.error(key + " is already exists !");
            }
            file[key] = "";
            this.$forceUpdate();
        },
        addFile()
        {
            if(!this.form.files) this.form.files = {};
            let name = window.prompt("Please Type the file name:");
            if(!name) return false;
            if(this.form.files[name]) 
            {
                this.clear();
                return this.error(name + " is already exists !");
            }
            this.form.files[name] = {};
            this.$forceUpdate();
            this.addLocale(name, "en");
        },
        removeLocale(name, locale)
        {
            if(this.form.files[name] === undefined) return false;
            if(this.form.files[name][locale] === undefined) return false;
            if(confirm('Do you want to remove the locale '+locale+' from '+name))
            {
                delete this.form.files[name][locale];
                this.$forceUpdate();
            }
        },
        removeFile(name)
        {
            if(this.form.files[name] === undefined) return false;
            if(confirm('Do you want to remove the file '+name))
            {
                delete this.form.files[name];
                this.$forceUpdate();
            }
        },
        getRepoURL()
        {
            if(this.form.repository_url)
            {
                this.form.repository_url_pure = this.form.repository_url.substr(this.form.repository_url.length - 4, 4) == '.git' 
                    ? this.form.repository_url.substr(0, this.form.repository_url.length - 4) : this.form.repository_url; 
            }
            else this.form.repository_url_pure = null;
        },
        loadBranchs(branches)
        {
            this.branches = this.getBranches(branches);
        },
        selectBranch(branch)
        {
            this.git('checkout', {branch});
        },
        urlCheck(status)
        {
            this.getRepoURL();
            if(!this.form) return;
            this.form.checked = status;
            this.$forceUpdate()
        }
    },
    
    created() {
        if(!this.perm('admin')) this.$router.push({name: "Projects"});
        if(isNaN(this.$route.params.id) && this.$route.params.id != 'add') this.$router.push({path: '/projects/add'});
    },
    destroyed() {            
        this.cancelRequest();
    },
    async mounted() {
        this.tags =  await this.settings('tags');
        this.form = {
            id: isNaN(this.$route.params.id) && this.$route.params.id == 'add' ? null : this.$route.params.id,
            tag: this.tags[0],
            status: true,
            files: {},
            email: this.auth?.user.email || ""
        };
        this.load();
    },
    watch: {
        '$route': {
            deep: true,
            handler()
            {
                console.log("Loaded", this.tags, {...this.form});
                this.form = {
                    id: isNaN(this.$route.params.id) && this.$route.params.id == 'add' ? null : this.$route.params.id,
                    tag: this.tags[0],
                    status: true,
                    files: {},
                    email: this.auth?.user.email || ""
                };
                this.load();
            }
        } 
    }, 
}
</script>