<template>
    <div class="container-fluid ">
        <nav class="bg-body-tertiary p-3 pb-1 rounded-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" v-if="current.Home">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" :class="{'active': name == active}" v-for="(url, name, i) in current" :key="i">
                    <b v-if="name == active">{{name}}</b>
                    <router-link v-else :to="url">{{name}}</router-link>
                </li>
                <!-- <li class="breadcrumb-item active" aria-current="page">Library</li> -->
            </ol>
        </nav>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState("Routes", ["current"]),
        active()
        {
            let keys = Object.keys(this.current || {});
            return keys[keys.length - 1];
        }
    },
}
</script>