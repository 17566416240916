<template>
    <div>
        <Header />
        <breadcrumb class="pt-5 mt-5 mb-3" />
        <messages />
        <router-view v-if="isStarted" class="container-fluid pb-4" v-slot="{ Component }">
            <component :is="Component"></component>
        </router-view>
        <Loading v-else />
        <Footer class="mt-3" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Login from './components/Login.vue'
import Breadcrumb from './layouts/Breadcrumb.vue';
import Footer from './layouts/Footer.vue';
import Header from './layouts/Header.vue';
import Loading from './layouts/Loading.vue';
import Messages from './plugins/Messages.vue';

export default {
    name: 'App',
    computed: {
        ...mapState({"isStarted": "init"}),
        ...mapState("Auth", ["auth"])
    },
    methods: {
        ...mapActions(["init"]),
        
    },
    components: {
    Login,
    Header,
    Footer,
    Loading,
        Breadcrumb,
        Messages
},
    async created() {
        await this.init();
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>

<style lang="scss">
@import "~font-awesome/css/font-awesome.css";
</style>
