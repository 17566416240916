<template>
    <div class="container">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Settings</h4>
            </div>
            <div class="card-body">
                <form @submit.prevent="save">
                    <div class="row">
                        <div class="col-md-3">App Name</div>
                        <div class="col-md">
                            <input type="text" :disabled="loading" v-model="form.AppName" class="form-control" required />
                        </div>
                    </div>
                    <div class="row mt-3">          
                        <div class="col-md-3">&nbsp;</div>          
                        <div class="col-md-9">
                            <button type="submit" :disabled="loading" class="btn btn-primary">
                                <i class="fa fa-save"></i>
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            loading: false,
            form: {},
        }
    },
    computed: {
        ...mapState("Settings", ['settings']),
    },
    methods: {
        ...mapActions("Settings", {'saveSettings': 'save'}),
        ...mapActions("Routes", ['setPath']),
        async save()
        {
            this.loading = true;
            this.$forceUpdate();
            await this.saveSettings(this.form);
            this.loading =  false;
            this.$forceUpdate();
        }
    },
    mounted() {
        if(!this.perm('admin')) this.$router.push({name: "Forbidden"});
        this.setPath({
            "Settings" : "/settings"
        });
    },
    watch: {
        settings: {
            immediate: true,
            deep: true,
            handler()
            {
                this.form = {...this.settings};
                this.$forceUpdate();
            }
        }
    },
}
</script>