<template>
    <nav aria-label="Page navigation" v-if="rs && rs.last_page > 1">
        <ul class="pagination ">
            <li class="page-item" :class="{'disabled': rs.current_page < 2 || loading}">
                <button type="button" class="page-link" :disabled="rs.current_page < 2 || loading" @click.prevent="setPage(1)">
                    <i class="fa fa-angle-double-left fliped"></i>
                </button>
            </li>
            <li class="page-item" :class="{'disabled': rs.current_page < 2 || loading}">
                <button type="button" class="page-link" :disabled="rs.current_page < 2 || loading" @click.prevent="setPage(rs.current_page - 1)">
                    <i class="fa fa-angle-left fliped"></i>
                </button>
            </li>

            <li v-for="(page, index) in pages" class="page-item" :key="index" :class="rs.current_page == page ? selectedClass || 'active' : null">
                <button type="button" v-if="page != '-'" class="page-link" href="#" @click.prevent="setPage(page)">{{page}}</button>
                <a v-if="page == '-'" class="page-link"> ... </a>
            </li>
            
            
            <li class="page-item" :class="{'disabled': rs.current_page >= rs.last_page || loading}">
                <button type="button" class="page-link" :disabled="rs.current_page >= rs.last_page || loading" @click.prevent="setPage(rs.current_page + 1)">
                    <i class="fa fa-angle-right fliped"></i>
                </button>
            </li>

            <li class="page-item" :class="{'disabled': rs.current_page >= rs.last_page || loading}">
                <button type="button" class="page-link" :disabled="rs.current_page >= rs.last_page || loading" @click.prevent="setPage(rs.last_page)">
                    <i class="fa fa-angle-double-right fliped"></i>
                </button>
            </li>
            <li v-if="loading" class="p-2">
               <i class="fa fa-spin fa-spinner"></i>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        rs: Object,
        callback: Function,
        selectedClass: {},
        loading: Boolean
    },
    data(){
        return {
            pages: []
        };
    },
    methods: {
        setPage(page)
        {
            if(this.callback) this.callback(page);

            return false;
        },
        start()
        {
            let pages = [];
            var rs = this.rs;
            if(rs && typeof rs == "object" && rs.last_page  && rs.last_page > 0)
            {
                var start = rs.current_page > 1 ? rs.current_page - 1 : rs.current_page;
                if(rs.current_page > 2) pages.push("-");
                pages.push(start);
                pages.push(++start);
                start++;
                if(start <= rs.last_page) pages.push(start);
                if(rs.current_page < rs.last_page - 1 && rs.last_page != start) pages.push("-");
            }

            this.pages = pages;
        }
    },
    mounted() {
        this.start();
    },
    watch: {
        rs()
        {
            this.start();
        },
        loading()
        {
            this.$forceUpdate();
        }
    },
}
</script>