<template>
    <div v-if="status" :class="'alert-'+status" class="alert m-3">
        <i class="fa float-start fs-4" :class="{
            'fa-info-circle': status == 'info',
            'fa-warning': status == 'warning',
            'fa-times': status == 'danger',
            'fa-check-circle': status == 'success',
            }"></i>
        <pre v-if="show">{{message.trim()}}

...<a class="pointer" @click="show = false;$forceUpdate()">less more</a>
        </pre>
        <div v-else><span class="readmore w-90">{{message.trim()}}</span>... <a class="pointer" @click="show = true;$forceUpdate()">show more</a></div>
    </div>
</template>


<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            show: false
        }
    },
    computed: {
        ...mapState("Messages", ['status', 'message'])
    },
    watch: {
        message: {
            immediate: true,
            handler()
            {
                this.show = false;
                this.$forceUpdate();
            }
        }
    },
}
</script>