export default {
    namespaced: true,
    state: {
        auth: {
            token: null,
            user: null
        }
    },
    getters: {
        perm: state => permissions =>
        {      
            if(typeof permissions == 'string') permissions = [permissions];
            if(!state.auth.user || !state.auth.user.permissions) return false;
            if(state.auth.user.permissions.includes('admin')) return true;
            let status = false;
            for(var i in permissions)
            {
                if(state.auth.user.permissions.includes(permissions[i]))
                {
                    status = true;
                    return status;
                }
            }
            return status;
        },
    },
    actions: {
        async perm({getters}, permissions)
        {
            return getters.perm(permissions);
        },
        async init({state, dispatch})
        {
            await dispatch("getUser");
            if(state.auth.user && state.auth.token)
            {
                try
                {
                    let res = await dispatch("Api/post", {
                            name: 'profile'
                        }, {root: true});
                    
                    if(res.results)
                    {
                        await dispatch("setUser", {
                            user: res.results,
                            token: state.auth.token
                        });
                    }
                    return res;
                }
                catch(e)
                {
                    return await dispatch("handleError", e, {root:true});
                }
            }
            await dispatch("check");
        },
        async check({state})
        {
            let meta = this._vm.$CurrentRoute.meta || {};
            // console.log("check auth", {...this._vm});
            if(meta.guest && state.auth.user)
            {
                this._vm.$CurrentRouter.push({name: "Home"});
            }
            else if(meta.auth && !state.auth.user)
            {
                this._vm.$CurrentRouter.push({name: "Login"});
            }
        },
        user({state})
        {
            return state.auth.user;
        },
        token({state})
        {
            return state.auth.token;
        },
        isLogin({state})
        {
            return state.auth.user && state.auth.user;
        },
        async setUser({state, dispatch}, data)
        {
            if(!data.token || !data.user)
            {
                state.auth = {
                    token: null,
                    user: null,
                };
            }
            else {
                state.auth.token = data.token;
                state.auth.user = data.user;
            }
            await dispatch("Storage/set", state.auth, { root: true });
            await dispatch("check");
        },
        async getUser({state, dispatch})
        {
            let token = await dispatch("Storage/getString", "token", { root: true });
            if(token === "") token = null;
            state.auth = {
                token,
                user: await dispatch("Storage/get", "user", { root: true }),
            }
            // console.log("user saved", state.auth);
        },
        async logout({dispatch})
        {
            
            let status = await dispatch("Api/post", {
                name: 'logout'
            }, {root: true});
            await dispatch('setUser', {});
            return status;
        },
        async save({dispatch, state}, params)
        {
            try
            {
                let response = await dispatch("Api/post", {
                    params,
                    name: 'saveProfile'
                }, {root: true});

                if(response && response.status)
                {
                    state.auth.user = response.results;
                }

                return response;
            }
            catch(e) { return await dispatch("handleError", e, {root: true})}
        }
    }
}