import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './RoutesList'
import NotFound from "@/components/NotFound"
routes.push({
    path: '*',
    name: 'NotFound',
    component: NotFound
});
const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes
});


//middleware
router.beforeEach(async(to, from, next) => {
    if(router.app.$store)
    {
        router.app.$store.dispatch("Messages/clear");
        router.app.$store.dispatch("Routes/setPath", {});
        let userStatus = await router.app.$store.dispatch("Auth/isLogin", null, { root: true });
        if (to.meta?.auth && !userStatus) next({ name: "Login" });
        else if (to.meta?.guest && userStatus) next({ name: "Home" });
        else 
        {
            if(to.meta?.perm && !await router.app.$store.dispatch('Auth/perm', to.meta.perm))
            {
                console.error("access denied", to.meta.perm);
                next({name: 'Forbidden'});
            }
            next();
        }    
    } else next();  
});




Vue.use(VueRouter);
// router.afterEach(() => {});
export default router;
