export default {
    "base_url": "v1/",
    "login": "user/auth",
    "logout": "user/logout",
    "profile": "user/profile",
    "saveProfile": "user/profile/update",
    "settings": "settings",
    "saveSettings": "admin/settings/save",
    "projects": "projects",
    "project": "projects/",
    "saveProject": "projects/save",
    "deleteProject": "projects/delete",
    "accounts": "admin/accounts",
    "saveAccount": "admin/accounts/save",
    // "deleteAccount": "admin/accounts/delete/",
    "deleteAccount": "admin/accounts/delete/force/",
}