import Vue from "vue";

Vue.directive('copy', {
// When the bound element is inserted into the DOM...
    inserted: function (el, val, vnode) {
        el.addEventListener('click', () => {
            let text = val.value || el.innerHTML;
            navigator.clipboard.writeText(text);
            vnode.context.$store.dispatch("Toast/add", {'message': "'"+text + "' has been copied to your clipboard", type: 'success'});
        });
    }
});