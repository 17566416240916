<template>
    <div>
        <h3 class="text-center alert alert-danger fs-2">
            <i class="fa fa-times-circle "></i>
            Oops. Access Denied 403
        </h3>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    methods: {
        ...mapActions("Routes", ['setPath'])
    },
    mounted() {
        this.setPath({
            "Access denied": '/'
        });
    },
}
</script>