export default {
    namespaced: true,
    state: {
        settings: {},
        tags_classes: ["table-primary", "table-success", "table-danger", "table-secondary", "table-dark"],
    },
    getters: {
        classing: state => tag => {
            if(state.settings?.tags)
            {
                let index = state.settings.tags.indexOf(tag);
                if(index != -1)
                {
                    return state.tags_classes[index];
                }
            }
            return "";
        }
    },
    actions: {
        async init({state, dispatch})
        {
            try
            {
                let settings = await dispatch("Api/post", {
                    name: 'settings',                    
                }, {root: true});
                state.settings = settings.results;
                // console.log("state.settings", state.settings);

            }
            catch(e) 
            {
                return dispatch('handleError', e, {root: true});
            }
        },
        get({state}, payload)
        {            
            if(typeof payload == 'string') payload = {key: payload};            
            return state.settings[payload.key || ""] || (payload.default || null);
        },
        async save({state, dispatch}, settings)
        {
            try
            {
                let response = await dispatch("Api/post", {
                    name: 'saveSettings',
                    params: {settings}                   
                }, {root: true});

                if(response.results?.public) state.settings = response.results?.public;
                else dispatch('handleError', {message: "Response is empty"}, {root: true});
                // console.log("state.settings", state.settings);

            }
            catch(e) 
            {
                return dispatch('handleError', e, {root: true});
            }
        }
    }
}