<template>
    <div class="container ">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">My profile 
                    <i class="fa fa-pencil pointer" v-if="!edit && !editPassword" @click="edit = true;$forceUpdate()"></i>
                    <button class="btn btn-sm btn-success float-end " type="button" v-if="!edit && !editPassword" @click="editPassword = true;form2 = {};$forceUpdate()">
                        Edit password
                    </button>
                </h4>
            </div>
            <div class="card-body">
                <form @submit.prevent="saveInfo">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="">Name</label>
                            <input :disabled="!edit || loading" type="text" v-model="form.name" class="form-control" required />
                        </div>
                        <div class="col-md-6">
                            <label for="">Email</label>
                            <input :disabled="!edit || loading" type="email" v-model="form.email" class="form-control" required />
                        </div>
                    </div>
                    <div class="row mt-2" v-if="edit">
                        <div class="col-md-12">
                            <button class="btn btn-primary" :disabled="loading" type="submit">
                                <i class="fa fa-spin fa-spinner" v-if="loading"></i>
                                Save
                            </button>
                            <button class="btn" @click="cancel" :disabled="loading" type="button">
                                cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="card mt-3" v-if="editPassword">
            <div class="card-header">
                <h4 class="card-title">Edit password</h4>
            </div>
            <div class="card-body">
                <form @submit.prevent="savePassword">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="">Current password</label>
                            <input v-focus  :disabled="loading" type="password" v-model="form2.current_password" class="form-control" required />
                        </div>
                        <div class="col-md-4">
                            <label for="">the new Password</label>
                            <input  :disabled="loading" type="password" v-model="form2.password" class="form-control" required />
                        </div>
                        <div class="col-md-4">
                            <label for="">Confirm new Password</label>
                            <input  :disabled="loading" type="password" v-model="form2.password_confirmation" class="form-control" required />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <button class="btn btn-primary" :disabled="loading" type="submit">
                                <i class="fa fa-spin fa-spinner" v-if="loading"></i>
                                Save
                            </button>
                            <button class="btn" @click="cancel" :disabled="loading" type="button">
                                cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data() {
        return {
            form: {},
            form2: {},
            edit: false,
            editPassword: false,
            loading: false
        }
    },
    computed: {
        ...mapState("Auth", ['auth'])
    },
    methods: {
        ...mapActions("Auth", ["setUser", "save"]),
        ...mapActions("Messages", ['hasError', 'error', 'success', 'clear']),
        async saveInfo()
        {
            this.clear();
            this.loading = true;
            this.$forceUpdate();
            let response = await this.save(this.form);
            this.loading = false;
            if(await this.hasError(response)) return this.$forceUpdate();
            this.cancel();
            this.success("Profile has been updated successfully");
        },
        async savePassword()
        {
            this.clear();
            if(this.form2.password != this.form2.password_confirmation) return this.error("Confirmation password is not correct");
            this.loading = false;
            this.$forceUpdate();
            let response = await this.save(this.form2);
            this.loading = false;
            if(await this.hasError(response)) return this.$forceUpdate();
            this.cancel();
            this.success("Password has been updated successfully");
        },
        load()
        {
            this.form = {...this.auth.user};
        },
        cancel()
        {
            this.edit = this.editPassword = null;
            this.load();
            this.$forceUpdate();
        }
    },
    mounted() {
        this.load();
    },
}
</script>