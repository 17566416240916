<template>
    <tr>
        <td>
            <b>{{id}}</b> 
            <i  :title="'Copy '+id+' to clipboard'" class="fa ms-2 fs-6 fa-copy pointer" v-copy="id"></i>
            <i  :title="'Delete '+id+''" class="fa fa-remove fs-5 ms-2 text-danger pointer" v-if="perm('admin')" @click="del"></i>
        </td>
        <td v-for="(langK, ii) in locales" :key="ii" 
            @mousemove="hover = langK" @mouseleave="hover = null">
            <input type="text" 
                @keyup.enter.prevent="update"  
                @keyup.esc.prevent="cancel" 
                @keydown.tab.prevent="updateAndNext"                
                v-focus :disabled="disabled" class="form-control" :class="'dir-'+langK" v-model="form" v-if="edit == langK" @blur="update" />
            <div v-else class="pointer d-block " @dblclick="selectToEdit(langK)" :class="'dir-'+langK + (hover == langK ? ' bg-warning-subtle' : '')">                
                {{row[langK]}} &nbsp;
                <i v-if="row[langK] && row[langK] != ''" :title="'Copy '+row[langK]+' to clipboard'" class="fa fa-copy pointer me-2" v-copy="row[langK]"></i>
                <i v-if="hover == langK" @click="selectToEdit(langK)" class="fa fa-pencil poiter float-end"></i>
            </div>         
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        row: {required: true},   
        id: {type: String, required: true},     
        locales: {required: true, type: Array},
        select: {},
        new: Boolean
    },
    data() {
        return {
            hover: null,
            edit: null,
            form: "",
            disabled: false
        }
    },
    methods: {
        isObject(row)
        {
            let isObject = false;
            for(var langK in this.locales)
            {
                if(typeof row[this.locales[langK]] == 'object')
                {
                    return isObject = true;                    
                }
            }

            return isObject;
        },
        selectToEdit(key)
        {
            if(this.disabled) return;
            this.form = this.row[key] || "";
            this.edit = key;
            this.$forceUpdate();
        },
        update()
        {
            this.disabled = true;
            this.$forceUpdate();
            let newValue = this.form.trim();
            let oldvalue = (this.row[this.edit] || "");
            if(newValue != oldvalue || this.select)
            {
                this.$emit('changed', this.id, this.edit, oldvalue, newValue);
            }
            this.cancel();
        },
        updateAndNext()
        {
            if(!this.edit) return;
            let nextIndex = this.locales.indexOf(this.edit);
            nextIndex++;            
            this.update();
            // console.log(">>>next", nextIndex, this.locales[nextIndex]);
            if(this.locales[nextIndex])
            {
                this.$nextTick(() => this.selectToEdit(this.locales[nextIndex]));
            }

        },
        cancel()
        {
            this.edit = null;
            this.disabled = false;
            this.form = "";
            this.$forceUpdate();
        },
        del() { this.$emit('delete') },
        checkEmpty() 
        {
            if(this.row)
            {
                for(var langK in this.locales)
                {
                    let key = this.locales[langK];
                    let val = this.row[key] || "";
                    if(!val || val == "") this.$emit('changed', this.id, key, "", this.id);
                    this.$forceUpdate();
                }
            }
        }
    },
    mounted() {
        if(!this.new) this.checkEmpty();
        else console.log("keyyy", this.new);
    },
    watch: {
        select:{
            immediate: true,
            deep: true,
            handler()
            {
                if(this.select) 
                {
                    // this.$nextTick(() => this.selectToEdit(this.select));
                    window.setTimeout(() => this.selectToEdit(this.select), 500);
                }
            }
        }
    },
}
</script>

