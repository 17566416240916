<template>
    <div class="toast-container  position-fixed top-0 end-0 pt-5 ">
        <div v-for="(row, i) in list" :key="i" :key_index="i" @blur="removeFromList(i)" v-toast 
            class="toast align-items-center border-0" role="alert" aria-live="assertive"
            :class="'text-bg-' + (row.type || 'primary')"
            aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    {{row.message}}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {Toast} from "bootstrap/dist/js/bootstrap"
import {v4 as uuidv4} from "uuid"
export default {
    data() {
        return {
            list: {},
        }
    },
    computed: {
        ...mapState("Toast", ["messages"]),
    },
    methods: {
        ...mapActions("Toast", ["remove"])
    },
    watch: {
        messages: {
            deep: true,
            immediate: true,
            handler() {
                let messages = this.messages;
                for(var i in messages)
                {                    
                    this.list[uuidv4()] = messages[i];
                    console.log("messages[i]", messages[i], this.list);
                    this.remove(i);
                    this.$forceUpdate();
                }
            }
        }
    },
    directives: {
        toast(e, e2, vnode)
        {   
            const toastBootstrap = Toast.getOrCreateInstance(e);
            toastBootstrap.show();
            e.addEventListener('hidden.bs.toast', () => {
                delete vnode.context.$data.list[e.attributes.key_index.value];
                vnode.context.$forceUpdate();
            });
        }
    }
}
</script>