export default {
    namespaced: true,
    state: {
        current: {},
        title: null,
        names: [],
    },
    actions: {
        setPath({state}, pages)
        {
            if(!pages || typeof pages != 'object') pages = {};
            state.current = {Home: "/", ...pages};
            state.names = Object.keys(state.current);
            state.title = state.names[state.names.length - 1];
        },
    }
}