export default {
    namespaced: true,
    state: {
        status: null,
        message: null
    },
    actions: {
        show({state}, payload)
        {
            if(!payload || typeof payload == 'string') payload = {message: payload || null};
            state.message = payload.message || null;            
            if(!payload.message) state.status = null;
            else state.status = payload.status || "info"
        },
        clear({dispatch})
        {
            dispatch('show', null);
        },
        success({dispatch}, message)
        {
            dispatch('show', {message, status: 'success'});
        },
        warning({dispatch}, message)
        {
            dispatch('show', {message, status: 'warning'});
        },
        info({dispatch}, message)
        {
            dispatch('show', {message, status: 'info'});
        },
        error({dispatch}, message)
        {
            dispatch('show', {message, status: 'danger'});
        },
        hasError({dispatch}, response)
        {
            if(!response || response.error || !response.results)
            {
                let message = response?.results?.message || response?.message || "Error";
                if(response.errors) message += "\n- " + response.errors.join("\n- ");
                dispatch('show', {message, status: 'danger'});
                return true;
            }
            
            return false;
        },
    }
}