export default {
    getLocales(list, rows)
    {
        if(!rows) rows = {};
        let subs = {};
        for(var lang in list)
        {            
            for(var key in list[lang] || [])
            {                
                if(typeof list[lang][key] == 'object')
                {
                    if(!subs[lang]) subs[lang] = {};
                    for(var k in list[lang][key])
                    {
                        subs[lang][key+"\\"+k] = list[lang][key][k];
                    }
                }
                else 
                {
                    if(!rows[key]) rows[key] = {};
                    rows[key][lang] = list[lang][key] || "";
                }
            }
        }
        // console.log({list, subs});
        if(Object.keys(subs).length > 0) rows = this.getLocales(subs, rows);
        return rows;
    },
    setLocales(list, laguages)
    {
        let files = {};
        for(var langK in laguages)
        {
            var l = laguages[langK];
            if(!files[l]) files[l] = {};
            for(var f in list)
            {
                let childs = f.split("\\");
                if(childs.length > 1)
                {
                    // console.log(f);
                    // console.log('files[l]', files[l]);
                    // console.log('list[f][l]', list[f][l]);
                    // console.log('childs', childs);
                    if(typeof files[l] == "string") 
                    {
                        var ll = files[l];
                        files[l] = {};
                        files[l][l] = ll;
                    }
                    files[l] = this.extend(files[l], this.setChilds(childs, list[f][l] || ""));
                }
                else files[l][f] = list[f][l] || "";
            }
        }

        return files;
    },
    setChilds(arrKeys, val)
    {
        if(arrKeys.length == 0) return val;
        else 
        {
            let k = arrKeys[0];
            arrKeys.splice(0, 1);
            let obj = {};
            obj[k] = this.setChilds(arrKeys, val);        
            return obj;
        } 
        
    },
    getBranches(branches)
    {
        let brnchs = [];
        for(var b in branches || {})
        {
            let brnch = branches[b];
            if(brnch.substr(0, 8) == 'remotes/')
            {
                brnch = brnch.split("/");
                brnch = brnch[brnch.length - 1];
                if(brnch == 'HEAD') brnch = null;
            }

            if(brnch && !brnchs.includes(brnch)) brnchs.push(brnch);
        }

        return brnchs;
    }
}